
<template>
  <div class="bg">
    <div class="wrapper">
      <div class="zz">
        <a-form
          ref="loginFromDom"
          class="login-form"
          :rules="rules"
          :model="formValue"
          :style="{width:'550px'}"
          @submit="submit"
        >
          <a-space class="title">
            找回密码
          </a-space>
          <a-form-item field="mobile" hide-label :validate-trigger="['change','input']">
            <a-input v-model="formValue.mobile" placeholder="请输入手机号" class="no-autofill" />
          </a-form-item>
          <a-form-item field="valCode" hide-label :validate-trigger="['change','input']">
            <a-input v-model="formValue.valCode" placeholder="请输入验证码" class="no-autofill">
              <template #append>
                <a-button type="text" style="color:#4F17A8;" @click="valHand" v-if="show" :disabled="dis">获取验证码</a-button>
                <a-button type="text" style="color:#4F17A8;" @click="valHand" v-else :disabled="dis">重新发送({{count}}s)</a-button>
              </template>
            </a-input>
          </a-form-item>
          <a-form-item field="password" hide-label :validate-trigger="['change','input']">
            <a-input-password v-model="formValue.password" placeholder="请输入新密码" class="no-autofill" />
          </a-form-item>
          <a-form-item field="confirmpassword" hide-label :validate-trigger="['change','input']">
            <a-input-password v-model="formValue.confirmpassword" placeholder="请再次输入新密码" class="no-autofill" />
          </a-form-item>
          <!-- <a-form-item field="isRead" :label-col-props="{ span: 0}">
            <a-checkbox v-model="formValue.isRead">
              阅读并同意<span style="color:#165BD3">用户协议</span>
            </a-checkbox>
          </a-form-item> -->
          <a-space>
            <a-button class="btn-foot" html-type="submit" style="background:#4F17A8;color:#fff" v-if="formValue.mobile !== '' && formValue.valCode !== '' && formValue.password !== ''  && formValue.confirmpassword !== '' && formValue.isRead == true">确认修改</a-button>
            <a-button class="btn-foot" style="background:#F7F8FA;;color:#C8C9CC;" disabled v-else>确认修改</a-button>
          </a-space>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {ref, reactive } from 'vue';
  import { useRoute, useRouter } from 'vue-router'
  import * as loginApi from '@/api/login';
  import md5 from 'js-md5';
  import { Message} from '@arco-design/web-vue';
import { async } from 'q';

  const router = useRouter()
  const route = useRoute()
  const getFrom = () => {
    return {
      mobile:'',
      valCode:'',
      password:'',
      confirmpassword:'',
      isRead: true,
    };
  };
  const formValue = ref(getFrom());
  const getRules = () => {
    return {
      mobile: [{ required: true, message: '手机号不能为空！'},{match: /^1[35789]\d{9}$/,message: '请输入有效手机号'}],
      valCode: [{ required: true, message: '请输入验证码'}],
      password: [
        { required: true, message: '请输入新密码'},
        { match: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,16}$/, message: '大写字母、小写字母、数字和特殊符号，这4类必须包括3类，长度8到16位'},
      ],
      confirmpassword: [
        { required: true, message: '请再次输入新密码'},
        { match: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,16}$/, message: '大写字母、小写字母、数字和特殊符号，这4类必须包括3类，长度8到16位'},
      ],
      isRead: [{ required: true, message: '请勾选'}],
    }
  }
  const rules = ref(getRules());
  //获取验证码
  const dis = ref(false)
  const show = ref(true)
  const timer = ref(null);
  const count = ref('');
  const valHand = () => {
    loginApi.valCode({mobile:formValue.value.mobile}).then(res => {
    })
    let time_count = 60;
    if (!timer.value) {
        count.value = time_count;
        show.value = false;
        dis.value = true;
        timer.value = setInterval(() => {
          if (count.value > 0 && (count.value <= time_count)) { 
            count.value--;
          }else {
            dis.value = false;
            show.value = true;
            clearInterval(timer.value);
            timer.value = null;
          }
        }, 1000);
      }
    
  };
  const loginFromDom = ref();
  //修改密码
  const submit = async () => {
    const errors = await loginFromDom.value?.validate();
    if (!errors) {
      if (formValue.value.password == formValue.value.confirmpassword) {
        let params = {
          mobile:formValue.value.mobile,
          valCode:formValue.value.valCode,
          password:md5(formValue.value.password),
        }
        loginApi.retrievePwd(params).then(res => {
          if(res.code == 20000){
            Message.success('修改成功');
            router.push({
              path: '/login',
            })
          }
        })
      }else{
        Message.warning('两次密码不一致');
      }
    }
  };
  const login = () => {
      if (route.path != '/') {
        route.query.url = route.path
      }
      router.push({
        path: '/login',
        query: route.query
      })
  }
</script>

<style lang="less" scoped>
.no-autofill {
  // -webkit-text-security: disc !important;
}
.bg {
  width: 100%;
  .zz {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-form {
    background: #FFFFFF;
    box-shadow: 0px 1px 8px 0px rgba(220,220,220,0.5000);
    border-radius: 4px;
    padding: 27px 10px;
    box-sizing: border-box;
  }
}
:deep(.arco-form-item){
  width: 300px;
}
:deep(.arco-form){
  align-items: center;
}
.title{
  width: 300px;
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #323233;
  line-height: 37px;
  margin-bottom: 26px;
}
:deep(.arco-checkbox){
  padding-left: 0;
}
.btn-foot{
  width: 300px;
}
.rou-log{
  width: 300px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #323233;
  line-height: 22px;
  margin-top: 8px;
  justify-content: flex-end;
}
</style>
